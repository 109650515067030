<template>
    <div>
        <VueApexCharts
            type="area"
            :options="chartOptions"
            :series="processedSeries"
            height="350"
        />
    </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
    components: {
        VueApexCharts,
    },
    props: {
        series: {
            type: [Array, Object],
            default: () => [],
        },
    },
    data() {
        return {
            chartOptions: this.getChartOptions(),
        };
    },
    computed: {
        translatedCategories() {
            return [
                this.$t("Monday"),
                this.$t("Tuesday"),
                this.$t("Wednesday"),
                this.$t("Thursday"),
                this.$t("Friday"),
                this.$t("Saturday"),
                this.$t("Sunday"),
            ];
        },
        processedSeries() {
            if (Array.isArray(this.series)) {
                return this.series;
            } else if (
                typeof this.series === "object" &&
                Object.keys(this.series).length
            ) {
                return [this.series];
            }
            return [];
        },
    },
    watch: {
        translatedCategories() {
            this.updateChartOptions();
        },
    },
    methods: {
        getChartOptions() {
            return {
                chart: {
                    type: "area",
                    height: 350,
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    curve: "smooth",
                },
                xaxis: {
                    type: "category",
                    categories: this.translatedCategories,
                },
                yaxis: {
                    tickAmount: 4,
                    labels: {
                        formatter: function (value) {
                            return value;
                        },
                    },
                },
                legend: {
                    show: false,
                },
                tooltip: {
                    x: {
                        format: "dd/MM/yy HH:mm",
                    },
                },
            };
        },
        updateChartOptions() {
            this.chartOptions = this.getChartOptions();
        },
    },
    mounted() {
        this.updateChartOptions();
    },
};
</script>

<style>
/* Add any custom styles here */
</style>
